import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "generate-an-activity-report"
    }}>{`Generate an Activity Report`}</h1>
    <hr></hr>
    <p>{`To generate an Activity Report, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Mangement System and click on Mobile Apps from the left side menu options.`}</li>
      <li parentName="ol">{`From the list, Select 'View Activity' next to the app name. `}</li>
      <li parentName="ol">{`Set a date range in the 'From' - 'Until' fields. `}</li>
      <li parentName="ol">{`Keep 'Include Extended Results' toggled if you would like all metrics to generate in the report. If you would like the report to only show Usage By Platform, Usage by Month, and LMS API Requests, toggle this off.`}</li>
      <li parentName="ol">{`Click 'Generate' `}</li>
    </ol>
    <p><img alt="CMS Generate Report" src={require("./images/cms_generate_report.gif")} />{` `}</p>
    <h3 {...{
      "id": "usage-by-platform"
    }}>{`Usage By Platform`}</h3>
    <hr></hr>
    <p>{`Provides usage information about device types, the number of distinct devices, and the launch count during the set time frame. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Platform Type`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Includes the different devices that have accessed your mobile app. Examples are Android, iPad, iPhone, etc.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Devices`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Counts the total amount for each different Platform Type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Launches`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total amount of times the app was launched per Platform Type.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "usage-by-month"
    }}>{`Usage By Month`}</h3>
    <hr></hr>
    <p>{`Provides information on the number of unique devices that have launched the app and the number of devices that had a first-time login during the set time frame.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Details the range you have set. For example, if you have set the report to show January - April, you will see Jan, Feb, Mar, Apr.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Devices`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total amount of devices that...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Launches`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total amount of times the app was launched during that month.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`New Devices`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total amount of new, first-time, logins for that month.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "lms-api-requests"
    }}>{`LMS API Requests`}</h3>
    <hr></hr>
    <p>{`Provides information on the number of failed and successful API requests along with the average duration it took for the request during the set time frame.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Details the range you have set. For example, if you have set the report to show January - April, you will see Jan, Feb, Mar, Apr.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Failed Requests`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total amount of failed API requests for that month.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Avg. Duration`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The average time of duration for the failed API requests in thousandths of seconds.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Successful Requests`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total amount of successful API requests for that month.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Avg. Duration Successes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The average time of duration for the successful API requests in thousandths of seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "lms-api-methods-used"
    }}>{`LMS API Methods Used`}</h3>
    <hr></hr>
    <p>{`Provides information on the number of times a specific API call was successfully requested.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cached Search`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of searches that were served from the search cache.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get Charges`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of time fees are retrieved from the ILS. This happens when a patron opens their fees page under their account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GetHolds-ILL`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GetHolds-ILS`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times a patron reviews their reservations page under their account. When the patron first logs in, the app retrieves their current list of physical holds from the ILS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GetItemImage`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times the app load an image.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GetLoans-ILS`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times that a patron reviews their checkouts. When a patron first logs in, the app retrieves their current list of physical checkouts from the ILS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GetPickupLocations`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times a pickup location is retrieved from the ILS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GetTitleDetails`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times that a patron opens the details view of any title.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GetUserDetails`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times that a patron opens their account. This includes selecting the image at the top of the account menu.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GetUserInfo`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times that a patron signs in or the app is re-opened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HoldCancelILS`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times a hold is cancelled in the ILS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HoldCreateILS`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times a hold is created in the ILS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HoldCreateDenied-ILS`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times a hold is denied from the ILS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LoanRenew`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times a checkout is renewed in the ILS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LRS Retrieval`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times when the app first communicates with ...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ProcessLogin`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of logins. This includes when the app is restarted and the patron is already authenticated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ProcessLogin-Denied`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of failed logins.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of searches that were performed in the app.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "total-page-views"
    }}>{`Total Page Views`}</h3>
    <hr></hr>
    <p>{`Provides information on the number of times a page, including its type, was displayed in the app. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page Type`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The type of page that was loaded within the app. Examples are Channel or Tab.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page Title`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The name of the page that was loaded or accessed within the app.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Views`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`How many times that Channel or Tab was loaded within the app.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "total-staff-collection-activity"
    }}>{`Total Staff Collection Activity`}</h3>
    <hr></hr>
    <p>{`Provides information on the number staff  actions for Click & Collect. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The Channel were the staff actions were performed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page Load`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of times the Channel's Staff Dashboard was opened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Session State`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of times "Enabled" or "Disabled" was set while the Staff Dashboard was open.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Items Pulled`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of times the "Items Pulled" button was clicked while in the Staff Dashboard.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Items Checked`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of times the "Checkout X item(s)" was clicked while in the Staff Dashboard.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Complete Sessions`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of times "Done" was clicked while in the Staff Dashboard.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancel Sessions`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of times "Re-shelve X item(s)" was click while in the Staff Dashboard.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set QueueMax`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of times the max queue limit was updated while in the Staff Dashboard.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Switch Channel`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of times the location was changed while in the Staff Dashboard.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Messages Sent`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of messages that were sent from the Staff Dashboard.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Actions`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`The total number of actions performed by staff members related to that specific Channel.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "total-user-collection-activity"
    }}>{`Total User Collection Activity`}</h3>
    <hr></hr>
    <p>{`Provides information on the number of patron actions for Click & Collect. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The Channel were the patron actions were performed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page Load`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of times the Click & Collect button was clicked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session Start`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of requests that were started when a patron requested to pickup their items.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`En Route`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of patrons who used the GPS/ETA options.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Arrived`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of pickup requests that made it to the status update display within the app.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Complete`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of requests that results in a checked out item for the patron.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cancel Sessions`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of requests that were cancelled by the patron at any point during the request for pickup.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Actions`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The total number of actions performed by patrons related to that specific Channel.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      